<template>
  <div class="goods-detail">
    <div class="content">
      <div class="goods-pic">
        <!-- <img src="../assets/images/pic.png" alt="" /> -->
        <img :src="goodInfo.imageUrl" alt="" />
      </div>
      <div class="goods-box">
        <div class="goods-name font-b font-bold">{{ goodInfo.title }}</div>
        <div class="goods-data-line">
          <div class="label font-m">{{ $i18n.t("gd_author") }}{{ goodInfo.author }}</div>
          <div class="data">
            <img src="../assets/images/icon-eye.svg" alt="" />
            <div class="num">{{ goodInfo.clicks }}</div>
          </div>
        </div>
        <div class="goods-data-line">
          <div class="label font-m">{{ $i18n.t("gd_chain_address") }}{{ goodInfo.address }}</div>
          <div class="data">
            <img src="../assets/images/icon-collet.svg" alt="" />
            <div class="num">{{ goodInfo.likes }}</div>
          </div>
        </div>
        <div class="goods-data-line">
          <!-- <div class="word font-m">{{ $i18n.t("gd_Introductions") }}</div> -->
          <div class="value">
            {{ goodInfo.description }}
          </div>
        </div>
      </div>
      <div class="time-box">
        <div class="tip">
          <span>{{ $i18n.t(`${isTasking ? 'rd_in_task' : isEnded ? 'rd_Completed' : 'gd_display' }`) }} - {{ $i18n.t("gd_remainder") }}</span>
          <div class="icon-que" @click="showQuesBomb = !showQuesBomb">
            <!-- <img src="../assets/images/icon-quer-nor.svg" alt="" /> -->
          </div>
        </div>
        <!-- <div class="tip" v-else>
          <span>{{ $i18n.t("gd_ended") }}</span>
        </div> -->
        <div class="count-down">
          <van-count-down :time="time" @finish="handleFinished">
            <template #default="timeData">
              <div class="block font-b">
                {{ timeData.days > 9 ? timeData.days : "0" + timeData.days }}
              </div>
              <div class="colon">:</div>
              <div class="block font-b">
                {{ timeData.hours > 9 ? timeData.hours : "0" + timeData.hours }}
              </div>
              <div class="colon">:</div>
              <div class="block font-b">
                {{ timeData.minutes > 9 ? timeData.minutes : "0" + timeData.minutes }}
              </div>
              <div class="colon">:</div>
              <div class="block font-b">
                {{ timeData.seconds > 9 ? timeData.seconds : "0" + timeData.seconds }}
              </div>
            </template>
          </van-count-down>
        </div>
        <!-- <div class="sub-tip">还没有出价，成为第一个出价的人!</div> -->
      </div>
      <div class="sala-data">
        <div class="data-item">
          <div class="word">NFT{{ $i18n.t("gd_price") }}</div>
          <div class="price">
            <img src="../assets/images/icon-eth-s.svg" alt="" />
            <div class="num font-b">{{ goodInfo.xPrice }} {{ currency }}</div>
          </div>
        </div>
        <div class="data-item">
          <div class="word">{{ $i18n.t("gd_income") }}</div>
          <div class="price">
            <img src="../assets/images/icon-eth-s.svg" alt="" />
            <div class="num font-b">{{ goodInfo.xRate }} {{ currency }}</div>
          </div>
        </div>
        <div class="data-item">
          <div class="word">{{ $i18n.t("gd_rate_of_return") }}</div>
          <div class="price">
            <div class="num font-b">{{ goodInfo.income }}%</div>
          </div>
        </div>
      </div>
      <!-- <div class="btn send-btn" v-if="!isDisabled" @click="openShow">
        {{ $i18n.t("gd_bid_price") }}
      </div> -->
      <van-button class="btn send-btn" round style="color: white" v-if="!isDisabled" :disabled="isDisabled" :loading="isBtnLoading" @click="openShow">{{ $i18n.t("gd_bid_price") }}</van-button>
      <div class="ques-content">
        <div class="color-999 sub f28">
          <span>{{ $i18n.t("gd_tips") }}</span>
          {{ $i18n.t("gd_tips_content") }}
        </div>
      </div>
    </div>
    <van-popup class="offer-popup" round v-model="showOfferBomb">
      <div class="offer-box">
        <div class="close" @click="closeShow">
          <img src="../assets/images/icon-close.svg" alt="" />
        </div>
        <div class="offer-data-list">
          <div class="data-item">
            <div class="word">{{ $i18n.t("gd_authorization_limit") }}</div>
            <div class="price">
              <img src="../assets/images/icon-eth-s.svg" alt="" />
              <div class="num font-b">{{ userInfo.approvedETH | substr61 }} {{ currency }}</div>
            </div>
          </div>
          <div class="data-item">
            <div class="word">{{ $i18n.t("gd_return") }}</div>
            <div class="price">
              <div class="num">{{ goodInfo.income }} %</div>
            </div>
          </div>
          <div class="data-item">
            <div class="word">{{ $i18n.t("gd_available_limit") }}</div>
            <div class="price">
              <img src="../assets/images/icon-eth-s.svg" alt="" />
              <div class="num font-b">{{ userInfo.availableETH | substr61 }} {{ currency }}</div>
            </div>
          </div>
          <div class="data-item">
            <div class="word">{{ $i18n.t("gd_income") }}</div>
            <div class="price">
              <img src="../assets/images/icon-eth-s.svg" alt="" />
              <div class="num font-b">{{ goodInfo.xRate }} {{ currency }}</div>
            </div>
          </div>
          <div class="data-item-fl">
            <div class="word">NFT {{ $i18n.t("gd_price") }}</div>
            <div class="price">
              <img src="../assets/images/icon-eth-s.svg" alt="" />
              <div class="num">{{ goodInfo.xPrice }} {{ currency }}</div>
            </div>
          </div>
          <!-- <div class="data-item">
            <div class="word">授权额度</div>
            <div class="price">
              <img src="../assets/images/icon-eth-s.svg" alt="" />
              <div class="num">25.366</div>
            </div>
          </div> -->
        </div>
        <van-button class="btn offer-btn" style="color: white" @click="confirmBuy" round :loading="isConfirming" :disabled="isConfirming">{{ $i18n.t("gd_Confirm") }}</van-button>
        <div class="offer-tip">{{ $i18n.t("gd_tips") }}{{ $i18n.t("gd_awarded_tips") }}</div>
      </div>
    </van-popup>
    <!-- <van-popup class="offer-popup" round v-model="showQuesBomb">
      <div class="offer-box">
        <div class="close" @click="showQuesBomb = false">
          <img src="../assets/images/icon-close.svg" alt="" />
        </div>
        <div class="ques-content">
          <div class="color-333 title f32">温馨提示：</div>
          <div class="color-999 sub f28">
            在任务进程中，你需要确保余额充足，否则任务会失败，信用分会被扣除。在任务进程中，你需要确保余额充足，否则任务会失败，信用分会被扣除。在任务进程中，你需要确保余额充足，否则任务会失败，信用分会被扣除。
          </div>
        </div>
      </div>
    </van-popup> -->
  </div>
</template>

<script>
// import dayjs from 'dayjs'
import { utcDateSubtract } from '../utils/day'
import config from "@/config";
function getFullNum(num){
    //处理非数字
    if(isNaN(num)){return num};
    //处理不需要转换的数字
    var str = ''+num;
    if(!/e/i.test(str)){return num;};
    return (num).toFixed(18).replace(/\.?0+$/, "");
}
export default {
  data() {
    return {
      isBtnLoading: false,
      currency: config.currency_eth,
      userInfo: {
        approvedETH: 0,
        availableETH: 0,      },
      goodId: null,
      goodInfo: {},
      orderId: null,
      showQuesBomb: false,
      showOfferBomb: false,
      time: 0,
      timeData: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      hPrice: 0,
      isDisabled: false,
      isEnded: false,
      isConfirming: false,
      isTasking: false
    };
  },
  filters: {
    substr61: function (val) {
      if (!val && val !== 0) return 0;
      val = typeof val === 'number' ? `${getFullNum(val)}` : val
      const [f, n] = val.split('.')
      return val == '0' ? 0 : `${f}${n ? `.${n.substr(0, 6)}` : ''}`
    }
  },
  created() {
    this.goodId = this.$route.query.id ? this.$route.query.id : "";
    this.loadInfo();
  },
  methods: {
    substr6(val) {
      val = typeof val === 'number' ? `${getFullNum(val)}` : val
      const [f, n] = val.split('.')
      return val == '0' ? 0 : `${f}${n ? `.${n.substr(0, 6)}` : ''}`
    },
    handleTime(date) {
      if (typeof date !== 'string') return date
      return date.replace(/-|\./g,"/").replace(' UTC', '')
    },
    handleFinished() {
      // this.isDisabled = true;
      // this.isEnded = true;
      // this.time = 0;
    },
    loadInfo() {
      let that = this;
      this.$http.getEthPrice().then((_res) => {
        if (_res.code === 0) {
          const price = _res.data.ethPrice.price;
          this.hPrice = price;
          this.$http.getGoodsInfo(this.goodId).then((res) => {
            if (res.code === 0) {
              that.goodInfo = res.data;
              that.goodInfo.address = that.goodInfo.address.slice(0, 6) + "******" + that.goodInfo.address.slice(-6);
              that.goodInfo.xPrice = that.substr6(that.goodInfo.price / price);
              that.goodInfo.xRate = that.substr6(((that.goodInfo.price * that.goodInfo.income * 0.01) / price));
              // this.goodInfo.showAt = this.handleTime(this.goodInfo.showAt)

              // const countTime = +dayjs(this.handleTime(that.goodInfo.showAt)) - (+dayjs());
              const countTime = utcDateSubtract(that.goodInfo.showAt);
              this.time = countTime > 0 ? countTime : 0;
              // 藏品展示时间小于60分钟不展示出价
              if (countTime < 60 * 60 * 1000) {
                this.isDisabled = true;
              }

              if (countTime < 0 || that.goodInfo.status === 'FINISHED') {
                this.isEnded = true;
                this.time = 0;
              }

              if (this.$route.query.isTasking === 'true') {
                this.isDisabled = true;
                this.isTasking = true
                const taskingTime = utcDateSubtract(that.goodInfo.endAt);
                this.time = taskingTime > 0 ? taskingTime : 0;
              }
            }
          });
        }
      });
    },
    async openShow() {
      this.isBtnLoading = true;
      let res = await this.$http.getUserInfo();
      if (res.code === 0) {
        this.showOfferBomb = true;
        this.userInfo = res.data;
        this.userInfo.approvedUSD = this.substr6(this.userInfo.approvedUSD / this.hPrice);
        this.userInfo.availableUSD = this.substr6(this.userInfo.availableUSD / this.hPrice);
      }
      this.isBtnLoading = false;
    },
    async confirmBuy() {
      // console.log(this.userInfo.availableETH, this.goodInfo.xPrice);
      if (+this.userInfo.availableETH < +this.goodInfo.xPrice) {
        this.$toast(this.$i18n.t("msg_not_funds"));
        return
      }
      let that = this;
      let data = {
        id: parseInt(this.goodId),
      };
      this.isConfirming = true;
      try {
        let res = await this.$http.buyGoods(data);
        if (res.code === 0) {
          //this.showOfferBomb = true;
          that.orderId = res.data.orderId;
          this.$toast(this.$i18n.t("order_checkout_success"));
          //order-info
          setTimeout(() => {
            // order?status=PENDING
            this.$router.push({path: "/order-info", query: {id: that.orderId}});
          }, 800);
        }
        this.isConfirming = false;
      } catch (error) {
        this.isConfirming = false;
      }
    },
    closeShow() {
      this.showOfferBomb = false;
    },
    onRefresh() {},
  },
};
</script>

<style lang="less">
.goods-detail {
  .content {
    padding: 1.36rem 0.48rem 1.6rem 0.48rem;
    .goods-pic {
      width: calc(100vw - 0.96rem);
      height: calc(100vw - 0.96rem);
      border-radius: 0.48rem;
      overflow: hidden;
      display: flex;
      img {
        width: 100%;
        height: calc(100vw - 0.96rem);
      }
    }
    .goods-box {
      text-align: left;
      margin-top: 0.32rem;
      padding-bottom: 0.15rem;
      border-bottom: 1px solid #f5f5f5;
      .goods-name {
        font-size: 0.32rem;
        color: #333;
        font-weight: bold;
        line-height: 0.48rem;
        margin-bottom: 0.3rem;
      }
      .goods-data-line {
        display: flex;
        justify-content: space-between;
        // height: 0.4rem;
        line-height: 0.4rem;
        margin-bottom: 0.15rem;
        .word {
          font-size: 0.28rem;
          color: #666;
          white-space: nowrap;
        }
        .label {
          width: calc(100% - 1.3rem);
          font-size: 0.28rem;
          color: #666;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .value {
          flex: 1;
          color: #666;
          text-align: left;
          font-size: 0.28rem;
          word-break: break-all;
          letter-spacing: 0.1px;
        }
        .data {
          width: 1.2rem;
          display: flex;
          justify-content: flex-end;
          img {
            width: 0.32rem;
            height: 0.32rem;
            margin-right: 0.08rem;
            margin-top: 0.03rem;
          }
          .num {
            font-size: 0.24rem;
            line-height: 0.44rem;
            color: #999;
          }
        }
      }
    }
    .time-box {
      border-bottom: 1px solid #f5f5f5;
      // padding: 0.3rem 0;
      .tip {
        padding: 0.3rem 0;
        font-size: 0.24rem;
        line-height: 0.36rem;
        color: #999;
        position: relative;
        .icon-que {
          position: absolute;
          right: 0;
          top: 0.3rem;
        }
      }
      .count-down {
        display: flex;
        justify-content: center;
        margin-bottom: 0.4rem;
        .van-count-down {
          display: flex;
          .block {
            width: 0.8rem;
            height: 0.8rem;
            background: rgba(110, 118, 145, 0.1);
            border-radius: 0.24rem;
            color: #333;
            font-size: 0.4rem;
            line-height: 0.8rem;
            letter-spacing: 3px;
            font-weight: 800;
          }
          .colon {
            font-size: 0.4rem;
            line-height: 0.8rem;
            font-weight: 800;
            margin: 0 0.3rem;
          }
        }
      }
      .sub-tip {
        font-size: 0.28rem;
        color: #999;
        line-height: 0.4rem;
        text-align: left;
        margin-bottom: 0.3rem;
      }
    }
    .sala-data {
      padding: 0.3rem 0;
      display: flex;
      .data-item {
        display: flex;
        flex-direction: column;
        .word {
          flex: 1;
        }
      }
      .data-item:nth-child(1),
      .data-item:nth-child(2) {
        width: 38%;
      }
      .data-item:nth-child(3) {
        width: 24%;
      }
    }
    .send-btn {
      width: 100%;
      font-weight: 400;
      margin: 0.3rem 0;
    }
  }
  .offer-popup {
    width: 86%;
    padding-bottom: 0.48rem;
    .offer-box {
      padding: 0 0.48rem;
      padding-top: 0.8rem;
      position: relative;
      .close {
        position: absolute;
        right: 0.3rem;
        top: 0.2rem;
      }
      .offer-data-list {
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid #f5f5f5;
        .data-item {
          width: 50%;
          margin-bottom: 0.44rem;
          &:nth-child(2n) {
            display: flex;
            flex-direction: column;
            align-items: end;
          }
        }
      }
      .offer-tip {
        font-size: 0.24rem;
        color: #999;
        line-height: 0.36rem;
        text-align: left;
        margin-top: 0.3rem;
      }
      .offer-btn {
        width: 100%;
        height: 0.8rem;
        line-height: 0.8rem;
        margin-top: 0.48rem;
        font-weight: 400;
      }
      .ques-content {
        text-align: justify;
        line-height: 0.4rem;
        .title {
          line-height: 0.48rem;
          margin-bottom: 0.1rem;
        }
        .sub {
          line-height: 0.48rem;
        }
      }
    }
  }
}
</style>
